import Aperol from '../assets/images/Aperol_Details.png';

const modalImageHelper = type => {
  switch (type) {
    case 'aperolMarch':
      return Aperol;
    case 'aperolApril':
      return Aperol;
    case 'aperolMay':
      return Aperol;
    default:
      return Aperol;
  }
};

export default modalImageHelper;
