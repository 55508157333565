import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Typography, Button } from '@material-ui/core';
import EventList from '../components/EventList';
import OwnedEventList from '../components/OwnedEventList';
import toastr from '../utils/toastr';
// fetch
import { getEvents, reserveEvent, addGuest, sendEmail } from '../fetch';

const Events = ({ history }) => {
  const classes = useStyles();
  const { auth } = useSelector(state => state);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [ownedEvents, setOwnedEvents] = useState([]);

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await getEvents(auth.splashToken);

      // match event owner on email
      const foundEvents = response.data.data.filter(event => event.event_setting.event_host && event.event_setting.event_host.toLowerCase() === auth.email.toLowerCase());
      if (foundEvents) {
        setOwnedEvents(foundEvents);
      }
      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      toastr('error', 'There was a problem loading event data. Please try again.');
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // reserve event
  const handleReserveEvent = async values => {
    setLoading(true);
    try {
      const data = {
        splashToken: auth.splashToken,
        eventId: values.sessionId,
        values: {
          event_host: auth.email
        }
      };

      await reserveEvent(data);

      const addGuestData = {
        splashToken: auth.splashToken,
        values: {
          event_id: values.sessionId,
          first_name: values.firstName,
          last_name: values.lastName,
          email: auth.email
        }
      };

      await addGuest(addGuestData);
      toastr('success', 'Event reserved successfully!');
      loadData();
      await sendEmail('New Event Reservation', values.sessionId, values.eventName, values.dateTime);
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      toastr('error', 'There was a problem reserving the event. Please try again.');
      setLoading(false);
    }
  };

  const [adding, setAdding] = useState(false);

  const handleAddEvent = () => {
    setAdding(true);
  };

  const handleLogout = () => {
    history.push('/thanks');
  };

  return (
    <div className={classes.events}>
      <Container maxWidth='xl' classes={{ root: classes.container }}>
        <Typography variant='h2' classes={{ root: classes.heading }}>2023 Roundel</Typography>
        <Typography variant='h2' classes={{ root: classes.heading2 }}>Virtual Experiences.</Typography>
        <Typography variant='h5' classes={{ root: classes.subHeading }}>
          An exclusive, limited-edition series of virtual events that give you and your clients an opportunity to have fun, learn something new, and create deeper connections from the comfort of your home or office.
        </Typography>

        {adding && (
          <Button
            variant='contained'
            classes={{ root: classes.cancelButton }}
            onClick={() => setAdding(false)}
          >
            Return to Dashboard
          </Button>
        )}

        {loading && (
          <div className={classes.loader}>Loading...</div>
        )}

        {/* Owned events */}
        {!loading && (ownedEvents.length > 0 && !adding) && (
          <OwnedEventList
            ownedEvents={ownedEvents}
            data={data}
            handleAddEvent={handleAddEvent}
            handleLogout={handleLogout}
          />
        )}

        {/* No owned events */}
        {!loading && (ownedEvents.length === 0 || adding) && (
          <EventList
            data={data}
            handleReserveEvent={handleReserveEvent}
            ownedEvents={ownedEvents}
          />
        )}
      </Container>
    </div>
  );
};

export default withRouter(connect(s => s)(Events));

const useStyles = makeStyles({
  events: {
    backgroundColor: '#F1F1F1',
    minHeight: '100vh',
    width: '100%',
    paddingBottom: '8rem'
  },
  container: {
    width: '100%',
    margin: '0',
    padding: '0 .5rem',
    '@media (min-width:600px)': {
      padding: '0 1.5rem',
    }
  },
  heading: {
    fontSize: 45,
    lineHeight: '41px',
    letterSpacing: '-1px',
    '@media (min-width:600px)': {
      paddingLeft: 16,
      fontSize: 70,
      lineHeight: '68px',
      letterSpacing: '-2.5px',
    },
    '@media (min-width:900px)': {
      maxWidth: 700,
    },
    color: '#0B0B0B',
    paddingTop: 20,
    fontFamily: 'TargetBold'
  },
  heading2: {
    fontSize: 45,
    lineHeight: '41px',
    letterSpacing: '-1px',
    paddingTop: 0,
    '@media (min-width:600px)': {
      paddingLeft: 16,
      fontSize: 70,
      lineHeight: '68px',
      letterSpacing: '-2.5px',
    },
    '@media (min-width:900px)': {
      maxWidth: 1200,
    },
    color: '#0B0B0B',
    fontFamily: 'TargetBold'
  },
  subHeading: {
    maxWidth: '90%',
    marginTop: '1rem',
    marginBottom: '1rem',
    fontSize: 16,
    lineHeight: '15px',
    fontFamily: 'TargetMedium',
    '@media (min-width:600px)': {
      paddingLeft: 16,
      fontSize: 18,
      lineHeight: '16px',
    },
    '@media (min-width:900px)': {
      fontSize: 24,
      lineHeight: '28px',
    },
    '@media (min-width:1200px)': {
      maxWidth: '55%',
    }
  },
  divider: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.5rem'
  },
  subHeading2: {
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    fontFamily: 'TargetMedium',
    '@media (min-width:600px)': {
      marginLeft: '1rem'
    }
  },
  stripe: {
    background: 'linear-gradient(90deg, rgba(234,67,243,1) 0%, rgba(237,108,77,1) 100%)',
    height: 16,
    flex: '1 1',
    marginTop: '.25rem',
    marginLeft: '.25rem',
    '@media (min-width:600px)': {
      marginLeft: '1rem',
    }
  },
  eventList: {
    marginTop: '.75rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    '@media (min-width:600px)': {
      justifyContent: 'flex-start'
    }
  },
  event: {
    margin: '1rem 0',
    width: 396,
    '@media (min-width:396px)': {
      height: 730,
    },
    '@media (min-width:600px)': {
      margin: '.25rem'
    }
  },
  loader: {
    marginTop: '4rem',
    margin: '0 auto',
    width: '10rem',
    textAlign: 'center',
    fontFamily: 'TargetMedium',
    fontSize: '3rem'
  },
  cancelButton: {
    color: 'white',
    marginTop: '.5rem',
    backgroundColor: 'black',
    fontFamily: 'TargetMedium',
    fontSize: 18,
    lineHeight: '18px',
    textTransform: 'none',
    letterSpacing: '1.92px',
    width: 151,
    height: 69,
    boxShadow: '0px 3px 6px #00000029',
    '&:hover': {
      backgroundColor: '#FF10FF',
      cursor: 'pointer',
      border: 'none'
    }
  },
});