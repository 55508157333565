import axios from 'axios';

const apiUrl = 'https://roundel-registration-api-thjoyvcbua-uc.a.run.app/api';

export const loginUser = async body => {
  try {
    const response = await axios.post(`${apiUrl}/users/login`, body);
    return response;
  } catch (error) {
    console.log('error', error)
  }
};

// https://roundel-registration-api-thjoyvcbua-uc.a.run.app/api/users/signup
// { "username": "username", "password": "password"}

export const splashLogin = async token => {
  try {
    const response = await axios.get(`${apiUrl}/users/token`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response;
  } catch (error) {
    console.log('error', error)
  }
};

export const getEvents = async splashToken => {
  try {
    const response = await axios.get(
      `https://api.splashthat.com/events?limit=100&published=true&event_start_after=${(new Date()).toISOString().split('T')[0]}&sort=event_start_asc&tag_names[0]=Roundel23`,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${splashToken}`,
        }
      }
    );
    return response;
  } catch (error) {
    console.log('error', error);
  }
};

export const reserveEvent = async data => {
  try {
    const response = await axios.patch(
      `https://api.splashthat.com/events/${data.eventId}/settings`,
      data.values,
      {
        headers: {
          Authorization: `Bearer ${data.splashToken}`
        }
      }
    )
    return response;
  } catch (error) {
    console.log('error', error);
    return error;
  }
};

export const addGuest = async data => {
  try {
    const response = await axios.post(
      `https://api.splashthat.com/groupcontact`,
      data.values,
      {
        headers: {
          Authorization: `Bearer ${data.splashToken}`
        }
      }
    )
    return response;
  } catch (error) {
    console.log('error', error);
    return error;
  }
};

export const getEventGuests = async (splashToken, eventId) => {
  try {
    const response = await axios.get(
      `https://api.splashthat.com/groupcontacts?event_id=${eventId}&limit=50&additional_columns[0]=group_contact_id`,
      {
        headers: {
          Authorization: `Bearer ${splashToken}`
        }
      }
    );
    return response;
  } catch (error) {
    console.log('error', error);
  }
};

export const deleteGuest = async (token, id) => {
  try {
    const response = await axios.put(
      `https://api.splashthat.com/groupcontact/${id}`,
      { 'status': 'waitlisted' },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response;
  } catch (error) {
    console.log('error', error);
    return error;
  }
};

export const sendEmail = async (action, id, name, dateTime) => {
  const emailData = {
    service_id: 'service_ru2hdjt',
    template_id: 'template_z27g6pb',
    user_id: 'user_4UNLi2efahhkVYWh8eV8j',
    template_params: {
      'action': action,
      'eventName': name,
      'eventId': id,
      'dateTime': dateTime
    }
  };

  try {
    const response = await axios.post('https://api.emailjs.com/api/v1.0/email/send', emailData);
    return response;
  } catch (error) {
    console.log('error', error);
    return error;
  }
};
