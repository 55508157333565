import AperolInstructor from '../assets/images/Aperol_Instructor.png';

const hostImageHelper = type => {
  switch (type) {
    case 'aperolMarch':
      return AperolInstructor;
    case 'aperolApril':
      return AperolInstructor;
    case 'aperolMay':
      return AperolInstructor;
    default:
      return;
  }
};

export default hostImageHelper;
