import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Container, Button, Typography, TextField, Grid, Box } from '@material-ui/core';
import Logo from '../assets/images/Roundel-Logo.png';
// fetch
import { loginUser, splashLogin } from '../fetch';

const Login = ({ history, updateAuth }) => {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [emailError, setEmailError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await loginUser({ username: email.replace(' ', ''), password: password.replace(' ', '') });
      const splashResponse = await splashLogin(response.data.token);

      updateAuth({
        email: email.replace(' ', ''),
        token: response.data.token,
        splashToken: splashResponse.data.access_token
      });

      setLoading(false);
      history.push('/home');
    } catch (error) {
      setLoading(false);
      setEmailError('Could not authenticate. Please check your email and password.')
      console.log('error', error);
    }
  };

  useEffect(() => {
    setEmailError(null);
  }, [email]);

  return (
    <div className={classes.login}>
      <div className={classes.wrapper}>
        <div className={classes.logoContainer}>
          <img src={Logo} className={classes.logo} alt='Roundel Logo' />
        </div>
        <Container maxWidth='md' classes={{ root: classes.container }}>
          <Typography variant='h3' classes={{ root: classes.title}}>Roundel Virtual Experiences</Typography>
          <Grid container spacing={2} classes={{ root: classes.formContainer }}>
            <Grid item xs={12}>
              <TextField
                id='email'
                InputProps={{ 
                  classes: {
                    root: classes.input,
                    error: classes.inputError
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    error: classes.labelError
                  }
                }}
                variant='filled'
                value={email}
                error={!!emailError}
                fullWidth
                onChange={e => {
                  setEmailError(null);
                  setEmail(e.target.value);
                }}
                helperText={emailError}
                FormHelperTextProps={{ style: { color: 'white', textAlign: 'center' }}}
                label='Email'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='password'
                type='password'
                InputProps={{ 
                  classes: {
                    root: classes.input,
                    error: classes.inputError
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    error: classes.labelError
                  }
                }}
                variant='filled'
                value={password}
                fullWidth
                onChange={e => setPassword(e.target.value)}
                FormHelperTextProps={{ style: { color: 'white', textAlign: 'center' }}}
                onKeyUp={e => {
                  if (e.key === 'Enter' || e.keyCode === 13) {
                    handleLogin();
                  }
                }}
                label='Password'
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6' classes={{ root: classes.linkText }}>
                  <a href='https://roundel.com/privacy-policy' rel='noreferrer' target='_blank' className={classes.link}>{' '}PRIVACY POLICY</a>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box classes={{ root: classes.buttonContainer }}>
                <Button variant='contained' classes={{ root: classes.button }} disabled={loading} onClick={() => handleLogin()}>Enter</Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default withRouter(connect(s =>s)(Login));

const useStyles = makeStyles(theme => ({
  login: {
    margin: 0,
    color: '#FFFFFF',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'flex-start',
    minWidth: '100vw'
  },
  wrapper: {
    minHeight: '50vh',
    width: '100%',
    background: '#000000',
    paddingTop: '3rem'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logoContainer: {
    width: '100%',
    textAlign: 'right',
    marginBottom: 35,
    '@media (min-width:500px)': {
      textAlign: 'right',
      marginBottom: 50,
    }
  },
  logo: {
    height: '1.5rem',
    paddingRight: 10,
    '@media (min-width:500px)': {
      height: '2.5rem',
      marginRight: 53
    }
  },
  title: {
    fontFamily: 'TargetMedium',
    fontSize: 40,
    textAlign: 'center'
  },
  formContainer: {
    marginTop: '2rem',
    maxWidth: 600
  },
  bodyText: {
    fontSize: 10,
    fontFamily: 'TargetMedium',
    marginTop: '.5rem'
  },
  input: {
    backgroundColor: 'white !important',
    borderBottom: 'none',
    borderRadius: 0,
    width: '90%',
    margin: '0 auto',
    '@media (min-width:500px)': {
      width: '50%',
    }
  },
  inputLabel: {
    backgroundColor: 'transparent',
    marginLeft: '5%',
    '@media (min-width:500px)': {
      width: '50%',
      marginLeft: '25%'
    }
  },
  inputError: {
    borderColor: 'white !important',
    backgroundColor: 'white'
  },
  labelError: {
    color: 'red !important',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '2rem'
  },
  button: {
    color: '#FFFFFF',
    backgroundColor: '#FF10FF',
    fontFamily: 'TargetMedium',
    fontSize: 26,
    borderRadius: 0,
    textTransform: 'none',
    letterSpacing: '1.92px',
    width: 123,
    height: 51,
    boxShadow: '0px 3px 6px #00000029',
    '&:hover': {
      background: '#F1F1F1',
      color: '#FF10FF',
      cursor: 'pointer',
      border: 'none'
    }
  },
  linkText: {
    color: 'white',
    display: 'flex',
    fontFamily: 'TargetMedium',
    justifyContent: 'center',
    fontSize: 14,
    flexDirection: 'column',
    '@media (min-width:500px)': {
      flexDirection: 'row'
    }
  },
  link: {
    color: 'white',
    display: 'flex',
    fontFamily: 'TargetMedium',
    fontSize: 14,
    marginLeft: '.25rem',
    '&:hover': {
      color: '#69ddff'
    }
  },
}));