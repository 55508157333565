import { default as toast } from 'toastr';

const toastr = (type = 'success', message = '', title = '', otherOptions = {}) => {
  toast[type](message, title, {
    positionClass: 'toast-top-center',
    closeButton: true,
    ...otherOptions
  });
};

export default toastr;
