import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import EventCard from './EventCard';
import SoldOutEventCard from './SoldOutEventCard';
import GenericEventModal from './GenericEventModal';

const EventList = ({ data, handleReserveEvent, ownedEvents }) => {
  const classes = useStyles();
  const { auth } = useSelector(state => state);

  const aperolMarch = data.filter(d => {
    return d.event_type.name === 'Exhibits & Shows';
  });

  const aperolApril = data.filter(d => {
    return d.event_type.name === 'Seminars & Workshops';
  });

  const aperolMay = data.filter(d => {
    return d.event_type.name === 'In-Store Engagement';
  });

  const isEventSoldOut = data => {
    const hasEventHost = val => val.event_setting.event_host;
    const soldOut = data.every(hasEventHost);
    return soldOut;
  };

  // modal 
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const handleShowModal = type => {
    switch (type) {
      case 'aperolMarch':
        setSelectedData(aperolMarch);
        setSelectedType('aperolMarch');
        break;
      case 'aperolApril':
        setSelectedData(aperolApril);
        setSelectedType('aperolApril');
        break;
      case 'aperolMay':
        setSelectedData(aperolMay);
        setSelectedType('aperolMay');
        break;
      default:
        setSelectedData(aperolMarch);
        setSelectedType('aperolMarch');
    }
    setIsModalShowing(true);
  };

  const handleClose = () => {
    setSelectedData(null);
    setSelectedType(null);
    setIsModalShowing(false);
  };

  return (
    <React.Fragment>
      <Box className={classes.divider} />
      <div className={classes.eventList}>
        {aperolMarch.length > 0 && (
          <div className={classes.event} onClick={() => handleShowModal('aperolMarch')}>
            {isEventSoldOut(aperolMarch) ? (
              <SoldOutEventCard data={aperolMarch} type='aperolMarch' />
            ) : (
              <EventCard data={aperolMarch} type='aperolMarch' />
            )}
          </div>
        )}
        {aperolApril.length > 0 && (
          <div className={classes.event} onClick={() => handleShowModal('aperolApril')}>
            {isEventSoldOut(aperolApril) ? (
              <SoldOutEventCard data={aperolApril} type='aperolApril' />
            ) : (
              <EventCard data={aperolApril} type='aperolApril' />
            )}
          </div>
        )}
        {aperolMay.length > 0 && (
          <div className={classes.event} onClick={() => handleShowModal('aperolMay')}>
            {isEventSoldOut(aperolMay) ? (
              <SoldOutEventCard data={aperolMay} type='aperolMay' />
            ) : (
              <EventCard data={aperolMay} type='aperolMay' />
            )}
          </div>
        )}
      </div>
      {isModalShowing && (
        <GenericEventModal
          open={isModalShowing}
          onClose={handleClose}
          data={selectedData}
          type={selectedType}
          onReserve={event => handleReserveEvent(event)}
          email={auth.email}
          ownedEvents={ownedEvents}
        />
      )}
    </React.Fragment>
  );
};

export default connect(s => s)(EventList);

const useStyles = makeStyles({
  divider: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.5rem'
  },
  eventList: {
    marginTop: '.75rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '@media (min-width:600px)': {
      justifyContent: 'flex-start'
    }
  },
  event: {
    margin: '1rem 0',
    width: 396,
    '@media (min-width:396px)': {
      height: 730,
    },
    '@media (min-width:600px)': {
      margin: '.25rem'
    }
  },
});