import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
  Modal,
  Card,
  CardContent,
  Button,
  IconButton,
  Typography,
  Backdrop,
  Fade,
  Grid,
  Dialog,
  DialogActions,
  Box,
  TextField
} from '@material-ui/core';
import InstaIcon from '../assets/images/instagram-icon.png';
import { eventText } from '../utils';
import modalImageHelper from '../utils/modalImageHelper';
import hostImageHelper from '../utils/hostImageHelper';

const GenericEventModal = ({ open, onClose, data, type, onReserve, email, ownedEvent }) => {
  const classes = modalStyles();

  const handleClose = () => {
    onClose();
  };

  const [isConfirmShowing, setIsConfirmShowing] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);

  const handleClickDate = session => {
    setSelectedSession(session);
    setAccepted(true);
  };

  const ConfirmDialog = props => {
    const { open } = props;
    return (
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby='confirm-dialog'
        maxWidth='lg'
      >
        <DialogActions classes={{ root: classes.dialogButtons }}>
          <Button
            variant='contained'
            onClick={handleCancel}
            classes={{ root: classes.cancelButton }}
          >
            On second thought, I'd like to keep looking
          </Button>
          <Button
            variant='contained'
            onClick={handleConfirm}
            classes={{ root: classes.acceptButton }}
          >
            Yes. Reserve This Class!
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const [accepted, setAccepted] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);

  const handleConfirm = () => {
    setAccepted(true);
    setIsConfirmShowing(false);
    handleAccept();
  };

  const handleCancel = () => {
    setAccepted(false);
    setIsConfirmShowing(false);
  };

  const handleAccept = () => {
    const day = new Date(selectedSession.event_start).toLocaleString().split(',')[0]
    const time = new Date(selectedSession.event_start).toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', timeZone: 'America/Chicago' });

    onReserve({
      sessionId: selectedSession.id,
      firstName: firstName,
      lastName: lastName,
      eventName: selectedSession.title,
      dateTime: `${time} on ${day}`
    });
    onClose();
  };

  const generateDateString = id => {
    const event = data.find(d => d.id === id);
    const year = new Date(event.event_start).getFullYear().toString().substr(2, 2);
    const day = new Date(event.event_start).toLocaleString().split(',')[0]
    const time = new Date(event.event_start).toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', timeZone: 'America/Chicago' });
    return `${day.slice(0, day.length - 4)}${year}, ${time} CST`;
  };

  return (
    <Modal
      aria-labelledby='connect-task-modal-total'
      aria-describedby='connect-task-modal-description'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card className={classes.root}>
          <IconButton classes={{ root: classes.close }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <CardContent>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography classes={{ root: classes.title }}>{eventText[type].title}</Typography>
              </Grid>
              <Grid item xs={12} classes={{ root: classes.hostWrap }}>
                <Typography classes={{ root: classes.host }}>with {eventText[type].host}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} classes={{ root: classes.left }}>
                <Grid container spacing={0}>
                  <Grid item xs={12} classes={{ root: classes.imageContainer}}>
                    <img className={classes.image} src={modalImageHelper(type)} alt='event snapshot' />
                  </Grid>
                  <Grid item xs={12}>
                    {!ownedEvent && (
                      !accepted ? (
                        <Grid container spacing={0} classes={{ root: classes.dates }}>
                          {data.map((date, i) => {
                            const year = new Date(date.event_start).getFullYear().toString().substr(2, 2);
                            const day = new Date(date.event_start).toLocaleString().split(',')[0]
                            const time = new Date(date.event_start).toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', timeZone: 'America/Chicago' });
                            return (
                            !date.event_setting.event_host ? (
                              <div key={`${date.event_start}_${i}`} className={classes.dateBoxAvailable} onClick={() => handleClickDate(date)}>
                                <Typography variant='body1' classes={{ root: classes.date }}>{`${day.slice(0, day.length - 4)}${year}`}, {time} CST</Typography>
                                <Typography variant='body1' classes={{ root: classes.book }}>BOOK NOW</Typography>
                              </div>
                            ) : (
                              <div key={`${date.event_start}_${i}`} className={classes.dateBoxBooked}>
                                <Typography variant='body1' classes={{ root: classes.date }}>{`${day.slice(0, day.length - 4)}${year}`}, {time} CST</Typography>
                                <Typography variant='body1' classes={{ root: classes.book }}>BOOK NOW</Typography>
                              </div>
                            ))}
                          )}
                        </Grid>
                      ) : (
                        <Grid container spacing={0} classes={{ root: classes.reserving }}>
                          <Grid item>
                              <div className={classes.dateBox}>
                                <Typography variant='body1' classes={{ root: classes.date }}>
                                  {generateDateString(selectedSession.id)}
                                </Typography>
                                <Typography variant='body1' classes={{ root: classes.book }}>RESERVING</Typography>
                              </div>
                          </Grid>
                        </Grid>
                      )
                    )}
                    {ownedEvent && (
                      <Grid container spacing={0} classes={{ root: classes.dates }}>
                        <div className={classes.dateBoxReserved}>
                          <Typography variant='body1' classes={{ root: classes.dateReserved }}>
                            {new Date(ownedEvent.event_start).toLocaleString().split(',')[0]}
                            {' '}
                            {new Date(ownedEvent.event_start).toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', timeZone: 'America/Chicago' })} CST
                          </Typography>
                          <Typography variant='body1' classes={{ root: classes.booked }}>BOOKED</Typography>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} classes={{ root: classes.right }}>
                <Grid container spacing={0} classes={{ root: classes.rightWrap }}>
                  <Grid item xs={12}>
                    <Typography variant='body1' classes={{ root: classes.description }}>{eventText[type].description}</Typography>
                  </Grid>
                  {!accepted ? (
                    <React.Fragment>
                      <Grid item xs={12}>
                        <Typography variant='body1' classes={{ root: classes.meet }}>Meet your instructor:</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='body1' classes={{ root: classes.about }}>{eventText[type].aboutHost}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className={classes.aboutHost}>
                          <div className={classes.hostLeft}>
                            <a className={classes.link} href={eventText[type].hostInsta} rel='noreferrer' target='_blank'>
                              <div className={classes.iconContainer}>
                                <img src={InstaIcon} alt='instagram-icon' className={classes.icon} />
                              </div>
                              <div className={classes.hostText}>
                                <Typography variant='body1' classes={{ root: classes.name }}>{eventText[type].hostName}</Typography>
                              </div>
                            </a>
                          </div>
                          <div className={classes.hostRight}>
                            <img src={hostImageHelper(type)} alt='instructor profile pic' className={classes.hostPic} />
                          </div>
                        </Box>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid item xs={12}>
                        <Typography variant='body1' classes={{ root: classes.info }}>Event Host (That's You!)</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <div className={classes.formWrap}>
                          <TextField
                            id='firstName'
                            InputProps={{ 
                              classes: {
                                root: classes.input,
                                error: classes.inputError
                              }
                            }}
                            InputLabelProps={{
                              classes: {
                                root: classes.inputLabel,
                                error: classes.labelError
                              }
                            }}
                            variant='filled'
                            value={firstName}
                            error={!!firstNameError}
                            fullWidth
                            onChange={e => {
                              setFirstNameError(null);
                              setFirstName(e.target.value);
                            }}
                            helperText={firstNameError}
                            FormHelperTextProps={{ style: { color: 'white' }}}
                            label='First Name'
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className={classes.formWrap}>
                          <TextField
                            id='lastName'
                            InputProps={{ 
                              classes: {
                                root: classes.input,
                                error: classes.inputError
                              }
                            }}
                            InputLabelProps={{
                              classes: {
                                root: classes.inputLabel,
                                error: classes.labelError
                              }
                            }}
                            variant='filled'
                            value={lastName}
                            error={!!lastNameError}
                            fullWidth
                            onChange={e => {
                              setLastNameError(null);
                              setLastName(e.target.value);
                            }}
                            helperText={firstNameError}
                            FormHelperTextProps={{ style: { color: 'white' }}}
                            label='Last Name'
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className={classes.formWrap}>
                          <TextField
                            id='email'
                            InputProps={{ 
                              classes: {
                                root: classes.input,
                                error: classes.inputError
                              }
                            }}
                            InputLabelProps={{
                              classes: {
                                root: classes.inputLabel,
                                error: classes.labelError
                              }
                            }}
                            variant='filled'
                            value={email}
                            fullWidth
                            label='Email'
                            disabled
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Box classes={{ root: classes.buttonContainer }}>
                          <Button
                            variant='contained'
                            classes={{ root: classes.button }}
                            onClick={() => setIsConfirmShowing(true)}
                            disabled={!firstName.length || !lastName.length}
                          >
                            Save
                          </Button>
                        </Box>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <ConfirmDialog
            title='Reserve Class'
            open={isConfirmShowing}
            onConfirm={handleAccept}
          />
        </Card>
      </Fade>
    </Modal>
  );
};

export default GenericEventModal;

const modalStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      position: 'absolute',
      width: '90%',
      maxWidth: 841,
      top: '50%',
      left: '50%',
      maxHeight: '80%',
      transform: 'translate(-50%, -50%)',
      overflow: 'scroll',
      borderRadius: 0,
      [theme.breakpoints.up('md')]: {
        width: '90%',
      },
    },
    close: {
      position: 'absolute',
      right: theme.spacing(0.25),
      top: theme.spacing(0.25),
      color: theme.palette.grey[600]
    },
    left: {
      marginTop: '1rem'
    },
    right: {
      marginTop: '1rem'
    },
    title: {
      fontFamily: 'TargetMedium',
      fontSize: 30,
      lineHeight: '30px',
      '@media (min-width:600px)': {
        fontSize: 39,
        lineHeight: '30px',
      },
      paddingTop: '1rem'
    },
    hostWrap: {
      paddingTop: 0
    },
    host: {
      fontFamily: 'TargetMedium',
      fontSize: 30,
      '@media (min-width:600px)': {
        fontSize: 39,  
      },
      lineHeight: '30px',
      marginBottom: 12,
      marginTop: '1rem'
    },
    imageContainer: {
      textAlign: 'center',
      maxHeight: 419,
      maxWidth: 396,
      margin: '0 auto',
      width: '100%',
      '@media (min-width:1200px)': {
        height: 419,
        width: 396
      }
    },
    image: {
      height: '100%',
      width: '100%'
    },
    description: {
      fontSize: 20,
      fontFamily: 'TargetMedium',
      lineHeight: '22px',
      width: '85%',
      marginTop: '.5rem'
    },
    info: {
      fontSize: 20,
      fontFamily: 'TargetMedium',
      lineHeight: '20px',
      marginTop: '2rem',
      width: '90%'
    },
    meet: {
      fontSize: 18,
      lineHeight: '21px',
      fontFamily: 'TargetMedium',
      marginTop: '2rem'
    },
    about: {
      fontSize: 18,
      lineHeight: '21px',
      fontFamily: 'TargetLight',
      width: '90%',
      whiteSpace: 'pre-line'
    },
    listHeader: {
      marginTop: '2rem',
      fontSize: 13,
      fontFamily: 'TargetMedium',
      lineHeight: '16px'
    },
    listItem: {
      fontSize: 13,
      fontFamily: 'TargetMedium',
      lineHeight: '13px',
      paddingTop: 4
    },
    rightWrap: {
      '@media (min-width:400px)': {
        marginLeft: '1rem'
      }
    },
    aboutHost: {
      display: 'flex',
      alignItems: 'flex-start',
      marginTop: '2rem',
      width: '90%',
      '@media (max-width:400px)': {
        position: 'relative',
      },
      '@media (max-width:800px)': {
        alignItems: 'center',
        flexDirection: 'column',
        // marginTop: '2rem'
      },
      '@media (min-width:800px)': {
        marginLeft: 0,
        // marginTop: '4rem'
      },
      '@media (min-width:1200px)': {
        width: '90%',
        // marginTop: '6rem'
      }
    },
    hostLeft: {
      display: 'flex'
    },
    hostRight: {
    },
    hostPic: {
      borderRadius: '50%',
      height: 107,
      width: 107,
      '@media (min-width:1200px)': {
        height: 214,
        width: 214,
      }
    },
    iconContainer: {
      height: '100%',
      width: 36,
      marginRight: '.5rem'
    },
    icon: {
      height: 36,
      width: 36
    },
    name: {
      fontSize: 12,
      fontFamily: 'TargetLight',
      lineHeight: '12px'
    },
    link: {
      textDecoration: 'none',
      color: 'black',
      display: 'flex'
    },
    dates: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginTop: '1rem',
      '@media (min-width:455px)': {
        justifyContent: 'flex-start'
      },
      '@media (min-width:600px)': {
        justifyContent: 'space-between',
      },
      '@media (min-width:876px)': {
        justifyContent: 'flex-start'
      }
    },
    dateBox: {
      border: '1px solid black',
      textAlign: 'center',
      width: 120,
      height: 42,
      margin: '.25rem 0 .25rem .25rem',

    },
    dateBoxAvailable: {
      border: '1px solid black',
      textAlign: 'center',
      width: 120,
      height: 42,
      margin: '.25rem 0 .25rem .25rem',
      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#FF10FF',
        cursor: 'pointer',
        border: '1px solid white'
      }
    },
    dateBoxBooked: {
      border: '1px solid lightgray',
      color: 'lightgray',
      textAlign: 'center',
      cursor: 'not-allowed',
      width: 120,
      height: 42,
      margin: '.25rem 0 .25rem .25rem',
    },
    date: {
      fontSize: 10,
      fontFamily: 'TargetMedium',
      lineHeight: '9.89px',
      letterSpacing: '-.4px',
      marginTop: '.5rem'
    },
    book: {
      fontSize: 16,
      fontFamily: 'TargetMedium',
      letterSpacing: '-.65px',
      lineHeight: '15.79px',
      marginTop: '.25rem',
      width: 88,
      marginRight: 'auto',
      marginLeft: 'auto'
    },
    dialogButtons: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '2rem 4rem',
      '@media (min-width:600px)': {
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '4rem 2rem 4rem',
        padding: '1rem'
      },
    },
    acceptButton: {
      borderRadius: 0,
      backgroundColor: 'black',
      color: 'white',
      fontSize: 17,
      lineHeight: '22px',
      fontFamily: 'TargetBold',
      height: 72,
      width: 160,
      textTransform: 'none',
      margin: '2rem auto',
      '@media (min-width:600px)': {
        marginLeft: '8rem'
      },
      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#FF10FF',
      }
    },
    cancelButton: {
      borderRadius: 0,
      backgroundColor: 'white',
      color: 'black',
      fontSize: 11,
      lineHeight: '13px',
      fontFamily: 'TargetBold',
      height: 72,
      width: 160,
      marginTop: '1rem',
      textTransform: 'none',
      border: '1px solid #525252',
      '@media (min-width:600px)': {
        marginTop: 0,
        marginRight: '8rem',
      },
      '&:hover': {
        border: '1px solid transparent'
      }
    },
    reserving: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    input: {
      backgroundColor: 'white',
      borderBottom: 'none'
    },
    inputLabel: {
      backgroundColor: 'transparent'
    },
    inputError: {
      borderColor: 'white !important',
      backgroundColor: 'white'
    },
    labelError: {
      color: 'red !important',
    },
    formWrap: {
      width: '95%',
      margin: '.5rem auto .5rem'
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '2rem',
      marginTop: '2rem'
    },
    button: {
      color: 'white',
      backgroundColor: 'black',
      fontFamily: 'TargetMedium',
      fontSize: 18,
      borderRadius: 0,
      textTransform: 'none',
      letterSpacing: '1.92px',
      width: 121,
      height: 51,
      boxShadow: '0px 3px 6px #00000029',
      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#FF10FF',
        cursor: 'pointer',
        border: 'none'
      }
    },
    dateReserved: {
      fontSize: 12,
      fontFamily: 'TargetMedium',
      lineHeight: '9.89px',
      letterSpacing: '-.4px',
      marginTop: '.5rem'
    },
    booked: {
      fontSize: 18,
      fontFamily: 'TargetMedium',
      letterSpacing: '-.65px',
      lineHeight: '15.79px',
      marginTop: '.5rem',
      width: 88,
      marginRight: 'auto',
      marginLeft: 'auto'
    },
    dateBoxReserved: {
      border: '1px solid black',
      borderRadius: 3.4,
      textAlign: 'center',
      width: 148,
      height: 48,
      margin: '0 auto'
    }
  };
});
