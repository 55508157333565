import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import toastr from '../utils/toastr';
import {
  Grid,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography
} from '@material-ui/core';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import DeleteIcon from '@material-ui/icons/Delete';
import BookedEventCard from '../components/BookedEventCard';
import OwnedEventModal from '../components/OwnedEventModal';
// fetch
import { addGuest, getEventGuests, deleteGuest, sendEmail } from '../fetch';

const defaultGuest = {
  first_name: '',
  last_name: '',
  primary_email: ''
};

const GuestEntry = ({
  ownedEvent,
  ownerEmail,
  history,
  selectedEvent,
  selectedType,
  deselectEvent
}) => {
  const classes = useStyles();
  const { auth } = useSelector(state => state);

  const [eventGuests, setEventGuests] = useState([defaultGuest]);

  const [touched, setTouched] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [dirty, setDirty] = useState(false);

  const getGuests = async () => {
    setLoading(true);
    try {
      const response = await getEventGuests(auth.splashToken, ownedEvent.id);
      const mapped = [];

      const isDeleted = guest => {
        if ((guest.event_rsvp && guest.event_rsvp.waitlist === true) || (guest && guest.deleted)) {
          return true;
        } else {
          return false;
        }
      };

      // filter out event host
      const filteredForHostAndAttendance = response.data.data.filter(d => d.contact.primary_email !== ownerEmail && (!isDeleted(d)));

      filteredForHostAndAttendance.forEach(g => {
         const guestData = {
          event_rsvp: {
            attending: g.event_rsvp ? g.event_rsvp.attending : undefined
          },
          bounced: g.contact.bounced,
          first_name: g.contact.first_name,
          last_name: g.contact.last_name,
          primary_email: g.contact.primary_email,
          id: g.id
        };

        mapped.push(guestData);
      });

      // add empty guests if needed
      if (mapped.length < 20) {
        for (let i = 0; mapped.length < 20; i++) {
          mapped.push(defaultGuest);
        }
      }

      let touchArray = [];
      let errorArray = [];
      for (let i = 0; i < 20; i++) {
        touchArray.push({ first_name: false, last_name: false, primary_email: false });
        errorArray.push({ first_name: null, last_name: null, primary_email: null });
      }
      setTouched(touchArray);
      setErrors(errorArray);

      setEventGuests(mapped);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error getting guests', error);
      toastr('error', 'There was a problem loading event guests. Please try again.');
    }
  };

  useEffect(() => {
    if (ownedEvent && ownedEvent.id) {
      getGuests();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // modal
  const [isModalShowing, setIsModalShowing] = useState(false);

  const handleShowModal = type => {
    setIsModalShowing(true);
  };

  const handleClose = () => {
    setIsModalShowing(false);
  };

  // form logic
  const isEmailValid = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.toLowerCase());
  };

  const handleChange = (field, val, index) => {
    setDirty(true);
    if (field === 'first_name') {
      const updated = [...eventGuests];
      const newValues = {
        ...updated[index],
        first_name: val
      };
      updated[index] = newValues;
      setEventGuests([...updated]);
    }
    if (field === 'last_name') {
      const updated = [...eventGuests];
      const newValues = {
        ...updated[index],
        last_name: val
      };
      updated[index] = newValues;
      setEventGuests([...updated]);
    }
    if (field === 'primary_email') {
      const updated = [...eventGuests];
      const newValues = {
        ...updated[index],
        primary_email: val
      };
      updated[index] = newValues;
      setEventGuests([...updated]);
    }
  };

  const handleBlur = (field, i) => {
    if (field === 'first_name') {
      const copy = [...touched];
      copy[i].first_name = true;
      setTouched(copy);

      if (!eventGuests[i].first_name.length) {
        // allow three blank fields
        if (!eventGuests[i].last_name.length && !eventGuests[i].primary_email.length) {
          const errorCopy = [...errors];
          errorCopy[i] = { first_name: null, last_name: null, primary_email: null };
          setErrors(errorCopy);
          return;
        }
        const errorCopy = [...errors];
        errorCopy[i].first_name = 'First Name is Required.';
        setErrors(errorCopy);
      } else {
        const errorCopy = [...errors];
        errorCopy[i].first_name = null;
        setErrors(errorCopy);
      }
    }
    if (field === 'last_name') {
      const copy = [...touched];
      copy[i].last_name = true;
      setTouched(copy);

      if (!eventGuests[i].last_name.length) {
        if (!eventGuests[i].first_name.length && !eventGuests[i].primary_email.length) {
          const errorCopy = [...errors];
          errorCopy[i] = { first_name: null, last_name: null, primary_email: null };
          setErrors(errorCopy);
          return;
        }
        const errorCopy = [...errors];
        errorCopy[i].last_name = 'Last Name is Required.';
        setErrors(errorCopy);
      } else {
        const errorCopy = [...errors];
        errorCopy[i].last_name = null;
        setErrors(errorCopy);
      }
    }
    if (field === 'primary_email') {
      const copy = [...touched];
      copy[i].primary_email = true;
      setTouched(copy);

      if (!isEmailValid(eventGuests[i].primary_email)) {
        if (!eventGuests[i].first_name.length && !eventGuests[i].last_name.length) {
          const errorCopy = [...errors];
          errorCopy[i] = { first_name: null, last_name: null, primary_email: null };
          setErrors(errorCopy);
          return;
        }
        const errorCopy = [...errors];
        errorCopy[i].primary_email = 'A Valid Email Address is Required.';
        setErrors(errorCopy);
      } else {
        const errorCopy = [...errors];
        errorCopy[i].primary_email = null;
        setErrors(errorCopy);
      }
    }
  };

  // add guests to event
  const handleSave = async shouldClose => {
    setSaving(true);
    try {
      for (const guest of eventGuests) {
        if (!guest.id && guest.first_name && guest.last_name && guest.primary_email) {
          const addGuestData = {
            splashToken: auth.splashToken,
            values: {
              event_id: ownedEvent.id,
              first_name: guest.first_name,
              last_name: guest.last_name,
              email: guest.primary_email
            }
          };
          await addGuest(addGuestData);
          setLoading(false);
        }
      }
      toastr('success', 'Guests saved successfully!');
      setDirty(false);

      const day = new Date(ownedEvent.event_start).toLocaleString().split(',')[0]
      const time = new Date(ownedEvent.event_start).toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', timeZone: 'America/Chicago' });

      await sendEmail('New Guest/s Added', ownedEvent.id, ownedEvent.title, `${time} on ${day}`);

      if (shouldClose) {
        deselectEvent();
        return;
      }

      const delayNavigation = setTimeout(() => {
        history.push('/thanks');
      }, 1000);
      return () => clearTimeout(delayNavigation);
    } catch (error) {
      setSaving(false);
      setDirty(false);
      console.log('error', error);
      toastr('error', 'There was a problem saving guests. Please try again.');
    }
  };

  const [isConfirmShowing, setIsConfirmShowing] = useState(false);
  const [guestIdToDelete, setGuestIdToDelete] = useState(null);

  const ConfirmDialog = props => {
    const { title, children, open } = props;
    return (
      <Dialog
        open={open}
        onClose={handleCancelDelete}
        aria-labelledby='confirm-dialog'
        maxWidth='md'
      >
        <DialogTitle id='confirm-dialog'>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions classes={{ root: classes.dialogButtons }}>
          <Button
            variant='contained'
            onClick={handleCancelDelete}
            classes={{ root: classes.cancelButton }}
          >
            Never mind, I don't want to do that.
          </Button>
          <Button
            variant='contained'
            onClick={handleConfirmDelete}
            classes={{ root: classes.acceptButton }}
          >
            Yes. Delete this guest.
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleCancelDelete = () => {
    setIsConfirmShowing(false);
  };

  const handleConfirmDelete = () => {
    setIsConfirmShowing(false);
    handleDeleteGuest();
  };

  const handleDeleteGuest = async () => {
    setLoading(true);
    try {
      await deleteGuest(auth.splashToken, guestIdToDelete);

      // this is the logic from getGuests()
      // calling get guests from here passes an undefined event id and auth token in the fetch
      // but copying the code and placing it here works just fine
      const response = await getEventGuests(auth.splashToken, ownedEvent.id);
      const mapped = [];

      const isDeleted = guest => {
        if ((guest.event_rsvp && guest.event_rsvp.waitlist) === true || (guest && guest.deleted)) {
          return true;
        } else {
          return false;
        }
      };

      // filter out event host
      const filteredForHostAndAttendance = response.data.data.filter(d => d.contact.primary_email !== ownerEmail && (!isDeleted(d)));

      filteredForHostAndAttendance.forEach(g => {
         const guestData = {
          event_rsvp: {
            attending: g.event_rsvp ? g.event_rsvp.attending : undefined
          },
          bounced: g.contact.bounced,
          first_name: g.contact.first_name,
          last_name: g.contact.last_name,
          primary_email: g.contact.primary_email,
          id: g.id
        };

        mapped.push(guestData);
      });

      // add empty guests if needed
      if (mapped.length < 15) {
        for (let i = 0; mapped.length < 15; i++) {
          mapped.push(defaultGuest);
        }
      }

      let touchArray = [];
      let errorArray = [];
      for (let i = 0; i < 15; i++) {
        touchArray.push({ first_name: false, last_name: false, primary_email: false });
        errorArray.push({ first_name: null, last_name: null, primary_email: null });
      }
      setTouched(touchArray);
      setErrors(errorArray);

      setEventGuests(mapped);

      setLoading(false);

      setGuestIdToDelete(null);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
      toastr('error', 'There was a problem deleting the guest. Please try again.');
      setGuestIdToDelete(null);
    }
  };

  const allNull = val => val.first_name === null && val.last_name === null && val.primary_email === null;

  const hasErrors = !errors.every(allNull);


  const handleNavigate = () => {
    if (!hasErrors) {
      handleSave(true);
    }
  };

  useEffect(() => {
    const homeButton = document.getElementById('simple-tab-0');
    if (homeButton) {
      homeButton.addEventListener('click', handleNavigate);
    }
    return () => {
      if (homeButton) {
        homeButton.removeEventListener('click', handleNavigate);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.cardContainer}>
        {selectedEvent && (
          <BookedEventCard
            handleClick={() => handleShowModal(selectedType)}
            type={selectedType}
            ownedEvent={ownedEvent}
          />
        )}
      </div>
      <div className={classes.guestEntryContainer}>
        {loading || saving ? (
          <div className={classes.loading}>{loading ? 'Loading' : 'Saving'} Guests...</div>
        ) : (
          <div className={classes.guestColumn}>
            {eventGuests.length > 0 && eventGuests.map((guest, i) => (
              guest.primary_email !== ownerEmail && (
                <Grid key={i} item xs={12} container spacing={0} classes={{ root: classes.guestRow }}>
                  <Grid item xs={12} classes={{ root: classes.infoRow }}>
                    <div className={classes.guestNumber}>
                      <div className={classes.invite}>Invitation #{i + 1}</div>
                      {guest && guest.bounced && (
                        <div><CancelScheduleSendIcon classes={{ root: classes.statusIconGrey }} /></div>
                      )}
                      {guest.event_rsvp && guest.event_rsvp.attending && (
                        <div><EventAvailableIcon classes={{ root: classes.statusIconGreen }}/></div>
                      )}
                      {guest.event_rsvp && guest.event_rsvp.attending === false && (
                        <div><EventBusyIcon classes={{ root: classes.statusIconRed }}/></div>
                      )}
                    </div>
                    <div className={classes.icons}>
                    {guest.id && (
                      <IconButton
                        aria-label='delete'
                        disabled={!guest.id}
                        onClick={() => {
                          setGuestIdToDelete(guest.id);
                          setIsConfirmShowing(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.formWrap}>
                      <TextField
                        id={`firstName_${i}`}
                        InputProps={{ 
                          classes: {
                            root: classes.input,
                            error: classes.inputError
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            root: classes.inputLabel,
                            error: classes.labelError
                          }
                        }}
                        variant='outlined'
                        disabled={guest.id}
                        value={guest.first_name}
                        error={touched[i] && touched[i].first_name && errors[i].first_name}
                        fullWidth
                        onChange={e => handleChange('first_name', e.target.value, i)}
                        onBlur={() => handleBlur('first_name', i)}
                        helperText={errors[i] && errors[i].first_name}
                        FormHelperTextProps={{ style: { color: 'rgba(237,108,77,1)' }}}
                        label='First Name'
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.formWrap}>
                      <TextField
                        id={`lastName_${i}`}
                        InputProps={{ 
                          classes: {
                            root: classes.input,
                            error: classes.inputError
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            root: classes.inputLabel,
                            error: classes.labelError
                          }
                        }}
                        variant='outlined'
                        disabled={guest.id}
                        value={guest.last_name}
                        error={touched[i] && touched[i].last_name && errors[i].last_name}
                        fullWidth
                        onChange={e => handleChange('last_name', e.target.value, i)}
                        onBlur={() => handleBlur('last_name', i)}
                        helperText={errors[i] && errors[i].last_name}
                        FormHelperTextProps={{ style: { color: 'rgba(237,108,77,1)' }}}
                        label='Last Name'
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes.formWrap}>
                      <TextField
                        id={`email_${i}`}
                        InputProps={{ 
                          classes: {
                            root: classes.input,
                            error: classes.inputError
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            root: classes.inputLabel,
                            error: classes.labelError
                          }
                        }}
                        variant='outlined'
                        disabled={guest.id}
                        value={guest.primary_email}
                        error={touched[i] && touched[i].primary_email && errors[i].primary_email}
                        fullWidth
                        onChange={e => handleChange('primary_email', e.target.value, i)}
                        onBlur={() => handleBlur('primary_email', i)}
                        helperText={errors[i] && errors[i].primary_email}
                        FormHelperTextProps={{ style: { color: 'rgba(237,108,77,1)' }}}
                        label='Email'
                      />
                    </div>
                  </Grid>
                </Grid>
              )
            ))}
          </div>
        )}
      </div>
      <div className={classes.infoContainer}>
        <div className={classes.legend}>
          <div className={classes.legendRow}>
            <EventAvailableIcon classes={{ root: classes.statusIconGreen }}/>
            <div className={classes.legendLabel}>RSVP - Yes</div>
          </div>
          <div className={classes.legendRow}>
            <EventAvailableIcon classes={{ root: classes.statusIconRed }}/>
            <div className={classes.legendLabel}>RSVP - No</div>
          </div>
          <div className={classes.legendRow}>
            <CancelScheduleSendIcon classes={{ root: classes.statusIconGrey }}/>
            <div className={classes.legendLabel}>Email Bounceback</div>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant='contained'
            classes={{ root: dirty ? classes.saveButtonDirty : classes.saveButton }}
            onClick={() => handleSave()}
            disabled={!dirty || hasErrors}
          >
            Save &amp; Logout
          </Button>
          <Button
            variant='contained'
            classes={{ root: classes.saveButtonDirty }}
            onClick={() => handleSave(true)}
            disabled={hasErrors}
          >
            Return to Dashboard
          </Button>
        </div>
      </div>
      <ConfirmDialog
        title='Delete Guest'
        open={isConfirmShowing}
        onConfirm={handleConfirmDelete}
      >
        <Typography variant='body1'>
          Are you sure you want to delete this guest?
        </Typography>
      </ConfirmDialog>
      {isModalShowing && (
        <OwnedEventModal
          open={isModalShowing}
          onClose={handleClose}
          type={selectedType}
          ownedEvent={selectedEvent}
        />
      )}
    </div>
  );
};

export default withRouter(connect(s => s)(GuestEntry));

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    flexWrap: 'wrap',
    marginLeft: '1rem',
    '@media (max-width:800px)': {
      justifyContent: 'flex-start'
    }
  },
  cardContainer: {
    width: 396,
    '@media (min-width:600px)': {
      marginRight: '8rem'
    }
  },
  guestEntryContainer: {
    width: 396,
    display: 'flex',
    flexDirection: 'column',
    margin: '2rem 0',
    '@media (min-width:600px)': {
      margin: '0 8rem 0 0'
    }
  },
  infoContainer: {
    width: 396,
    display: 'flex',
    justifyContent: 'space-between'
  },
  legendRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '.5rem',
    fontFamily: 'TargetBold',
    fontSize: 16
  },
  legendLabel: {
    marginLeft: '1rem'
  },
  guestColumn: {
    maxHeight: 672,
    overflowY: 'scroll'
  },
  guestRow: {
    marginTop: '1rem'
  },
  guestNumber: {
    display: 'flex'
  },
  input: {
    backgroundColor: 'white',
    borderBottom: 'none'
  },
  inputLabel: {
    backgroundColor: 'transparent'
  },
  inputError: {
    borderColor: 'white !important',
    backgroundColor: 'white'
  },
  labelError: {
    color: 'red !important',
  },
  formWrap: {
    width: '95%',
    margin: '.5rem auto .5rem 0'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '2rem',
    height: '12rem'
  },
  saveButton: {
    color: 'black',
    marginTop: '.5rem',
    backgroundColor: 'transparent',
    fontFamily: 'TargetMedium',
    fontSize: 18,
    lineHeight: '18px',
    borderRadius: 0,
    textTransform: 'none',
    letterSpacing: '1.92px',
    width: 151,
    height: 69,
    boxShadow: '0px 3px 6px #00000029'
  },
  saveButtonDirty: {
    color: 'white',
    marginTop: '.5rem',
    backgroundColor: 'black',
    fontFamily: 'TargetMedium',
    fontSize: 18,
    lineHeight: '18px',
    borderRadius: 0,
    textTransform: 'none',
    letterSpacing: '1.92px',
    width: 151,
    height: 69,
    boxShadow: '0px 3px 6px #00000029',
    '&:hover': {
      backgroundColor: '#FF10FF',
      cursor: 'pointer',
      border: 'none'
    }
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  statusIcon: {
    marginLeft: '1rem'
  },
  statusIconGreen: {
    marginLeft: '1rem',
    color: '#70EE48'
  },
  statusIconRed: {
    marginLeft: '1rem',
    color: '#EC5027'
  },
  statusIconGrey: {
    marginLeft: '1rem',
    color: '#8E8E8E'
  },
  dialogButtons: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '2rem 4rem',
    '@media (min-width:600px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: '4rem 2rem 4rem',
      padding: '1rem'
    },
  },
  invite: {
    fontFamily: 'TargetBold'
  },
  acceptButton: {
    backgroundColor: 'black',
    color: 'white',
    fontSize: 17,
    lineHeight: '22px',
    fontFamily: 'TargetBold',
    borderRadius: 0,
    height: 72,
    width: 157,
    textTransform: 'none',
    margin: '2rem auto',
    '@media (min-width:600px)': {
      marginLeft: '8rem'
    },
    '&:hover': {
      backgroundColor: '#FF10FF',
    }
  },
  cancelButton: {
    backgroundColor: 'white',
    color: 'black',
    fontSize: 11,
    lineHeight: '13px',
    fontFamily: 'TargetBold',
    borderRadius: 0,
    height: 72,
    width: 160,
    marginTop: '1rem',
    textTransform: 'none',
    border: '1px solid #525252',
    '@media (min-width:600px)': {
      marginTop: 0,
      marginRight: '8rem',
    },
    '&:hover': {
      border: '1px solid transparent'
    }
  },
  loading: {
    fontFamily: 'TargetMedium',
    fontSize: 16
  }
});