export const eventText = {
  aperolMarch: {
    title: 'I’m More than an Aperol Spritz!',
    description: 'Attendees will receive a gift box with cocktail making supplies.',
    frontDescription: 'Discover how to use Aperol in deliciously unexpected ways. From low-ABV cocktails perfect for days when you want to drink lightly, to recipes with a kick for nights that call for something stronger. Alcohol-free? Natasha’s mixing up mocktails too!',
    host: 'Natasha David',
    aboutHost: 'Natasha David is an internationally recognized and award-winning mixologist, author and entrepreneur. \n\nHer first book, Drink Lightly, as well as many of her cocktails, have been featured in countless publications, books and online journals, from The New York Times, Wine & Spirits Magazine and The Wall Street Journal to the Cocktail Codex, Nylon, GQ and Vogue. Her many awards include Zagat’s 30 Under 30, Eaters New York Bartender of the Year, Eaters National Bartender of the Year, a Star Chefs Rising Star and Imbibe Magazine’s 2020 Bartender of the Year. She opened and operated NYC’s Nitecap from 2014 to 2020. Today, she runs the bar and beverage consulting company You & Me Cocktails with her partner Jeremy Oertel. Together they help craft beverage programs for some of today’s hottest destinations, from the Soho Grand and The Roxy to Hotel Kinsley and Inness.',
    hostName: 'Natasha David',
    hostInsta: 'https://www.instagram.com/natashadavidxo/'
  },
  aperolApril: {
    title: 'I’m More than an Aperol Spritz!',
    description: 'Attendees will receive a gift box with cocktail making supplies.',
    frontDescription: 'Discover how to use Aperol in deliciously unexpected ways. From low-ABV cocktails perfect for days when you want to drink lightly, to recipes with a kick for nights that call for something stronger. Alcohol-free? Natasha’s mixing up mocktails too!',
    host: 'Natasha David',
    aboutHost: 'Natasha David is an internationally recognized and award-winning mixologist, author and entrepreneur. \n\nHer first book, Drink Lightly, as well as many of her cocktails, have been featured in countless publications, books and online journals, from The New York Times, Wine & Spirits Magazine and The Wall Street Journal to the Cocktail Codex, Nylon, GQ and Vogue. Her many awards include Zagat’s 30 Under 30, Eaters New York Bartender of the Year, Eaters National Bartender of the Year, a Star Chefs Rising Star and Imbibe Magazine’s 2020 Bartender of the Year. She opened and operated NYC’s Nitecap from 2014 to 2020. Today, she runs the bar and beverage consulting company You & Me Cocktails with her partner Jeremy Oertel. Together they help craft beverage programs for some of today’s hottest destinations, from the Soho Grand and The Roxy to Hotel Kinsley and Inness.',
    hostName: 'Natasha David',
    hostInsta: 'https://www.instagram.com/natashadavidxo/'
  },
  aperolMay: {
    title: 'I’m More than an Aperol Spritz!',
    description: 'Attendees will receive a gift box with cocktail making supplies.',
    frontDescription: 'Discover how to use Aperol in deliciously unexpected ways. From low-ABV cocktails perfect for days when you want to drink lightly, to recipes with a kick for nights that call for something stronger. Alcohol-free? Natasha’s mixing up mocktails too!',
    host: 'Natasha David',
    aboutHost: 'Natasha David is an internationally recognized and award-winning mixologist, author and entrepreneur. \n\nHer first book, Drink Lightly, as well as many of her cocktails, have been featured in countless publications, books and online journals, from The New York Times, Wine & Spirits Magazine and The Wall Street Journal to the Cocktail Codex, Nylon, GQ and Vogue. Her many awards include Zagat’s 30 Under 30, Eaters New York Bartender of the Year, Eaters National Bartender of the Year, a Star Chefs Rising Star and Imbibe Magazine’s 2020 Bartender of the Year. She opened and operated NYC’s Nitecap from 2014 to 2020. Today, she runs the bar and beverage consulting company You & Me Cocktails with her partner Jeremy Oertel. Together they help craft beverage programs for some of today’s hottest destinations, from the Soho Grand and The Roxy to Hotel Kinsley and Inness.',
    hostName: 'Natasha David',
    hostInsta: 'https://www.instagram.com/natashadavidxo/'
  },
};
