import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@material-ui/core';

const Faqs = () => {
  const classes = useStyles();

  return (
    <div className={classes.faqs}>
      <Container maxWidth='xl' classes={{ root: classes.container }}>
        <Typography variant='h2' classes={{ root: classes.heading }}>2023 Roundel</Typography>
        <Typography variant='h2' classes={{ root: classes.heading2 }}>Virtual Experiences FAQS.</Typography>
        <Box classes={{ root: classes.questions }}>
          <Typography variant='h5' classes={{ root: classes.question }}>What are virtual events?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>An intimate, invite-only virtual event and networking opportunity for you and your clients.
Think tutorials and activities from today’s hottest tastemakers, followed by a casual networking session with you and your guests.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>Why are we doing this?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>To help foster and build client relationships! This is a chance for you to hang out with your clients and enjoy a fun, exclusive experience together.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>Why will your client be excited to join</Typography>
          <List>
            <ListItem className={classes.item}>
              <ListItemText>
                <Typography variant='h6' classes={{ root: classes.answer }}>They get an experience kit full of goodies</Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.item}>
              <ListItemText>
                <Typography variant='h6' classes={{ root: classes.answer }}>They get to learn something new</Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.item}>
              <ListItemText>
                <Typography variant='h6' classes={{ root: classes.answer }}>They can interact and ask live questions from expert tastemakers</Typography>
              </ListItemText>
            </ListItem>
          </List>

          <Typography variant='h5' classes={{ root: classes.question }}>Can I invite my Canada Clients?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>Yes! If they need assistance inputting their address, reach out to <a className={classes.link} href='mailto:info@roundelevents.com'>info@roundelevents.com</a>.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>How long is the class?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>Each virtual experience is scheduled for an hour. This will include dedicated time with your guest instructor as well as a chance to casually network, chat, and connect with the group.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>How many people can I invite?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>Up to 10 people, including your colleagues.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>Can I invite more than 10 people?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>Reach out to <a className={classes.link} href='mailto:info@roundelevents.com'>info@roundelevents.com</a> to request additional seats.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>Do I also have to register for the class?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>Yes! If you want an experience kit, make sure to RSVP when you receive the invitation.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>Can I request a different event date?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>We strongly encourage you to check your schedule and your clients availability before confirming your date. If you need to, reach out to <a className={classes.link} href='mailto:info@roundelevents.com'>info@roundelevents.com</a> to see what your cancellation/rescheduling options are.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>The event I want is sold out, what can I do?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>Choose another class, they're all fantastic and fun! You can always send your comments/requests to <a className={classes.link} href='mailto:info@roundelevents.com'>info@roundelevents.com</a> and we will try our best to accommodate them.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>I signed up for an event but need to move the date, what can I do?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>We strongly encourage you to check your schedule and your clients availability before confirming your date. If you need to, reach out to <a className={classes.link} href='mailto:info@roundelevents.com'>info@roundelevents.com</a> to see what your cancellation/rescheduling options are.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>When do the email invites go to our clients?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>Your clients will receive an email invite once you sign them up.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>Can I change the clients I invited? I need to add one/remove one.</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>Yes, you can. Log back into your chosen class, click the trash can icon next to the guest you'd like to remove, then add another in their place.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>What is in each experience kit?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>Each class has its very own experience kit which will be mailed to your clients’ doorsteps. Kits include tools needed for class; recipe cards, shopping lists...</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>Do I get an activity kit?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>Yes! Don’t forget to RSVP when you receive the email invitation.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>What do I need to do to prepare for my class?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>Some classes have a few things they suggest you prepare ahead of the event, but you can still participate even if you aren’t able to prep before class. We’ll supply suggested equipment and a shopping list in your experience kit, which will arrive at your doorstep one week prior to the event.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>Does everyone have to participate or can they just watch?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>These are small group classes and everyone is encouraged to follow along to get the most fun out of the experience. However, you don’t have to participate and can just watch if you’d like. But those who do follow along get to enjoy a delicious homemade food or beverage during the networking portion of the event.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>My client hasn't received their email invite, what can I do?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>Notify <a className={classes.link} href='mailto:info@roundelevents.com'>info@roundelevents.com</a> and we will be able to assist you.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>My client hasn't received their experience kit, what can I do?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>Notify <a className={classes.link} href='mailto:info@roundelevents.com'>info@roundelevents.com</a> and we will be able to assist you.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>How do I know if my client has registered?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>You can log in and see if your client has RSVPd or not. If they have not, this is a good opportunity to send them a reminder to register.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>Can I send a direct link to my client? Their email seems to have blocked the invite</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>Yes, there is an event link we can send to you. Email <a className={classes.link} href='mailto:info@roundelevents.com'>info@roundelevents.com</a> with the event name, date, and time that you are hosting along with the name and email address of the guest you’d like to invite and we’ll get that sent your way.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>Can I invite people from my team?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>Yes – but they count towards the 10 guest max.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>Is this a webinar? Will all attendees be able to see and speak to each other?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>Yes! You will be able to see and hear all the attendees throughout this virtual social experience.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>Who should we contact if we have technical difficulty?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>Contact <a className={classes.link} href='tel:3109931449'>310-993-1449</a> or send an email to <a className={classes.link} href='mailto:info@roundelevents.com'>info@roundelevents.com</a>.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>Will guests be on camera?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>Yes, guests are welcome and encouraged to have their cameras on throughout the experience so they can interact with the class and the instructor.</Typography>

          <Typography variant='h5' classes={{ root: classes.question }}>When will my clients and myself get the experience kits?</Typography>
          <Typography variant='h6' classes={{ root: classes.answer }}>Experience kits will arrive to you and your clients’ doorsteps the week before the event. You will also get an emailed shopping list to help prepare for the class.</Typography>
        </Box>
      </Container>
    </div>
  );
};

export default Faqs;

const useStyles = makeStyles({
  faqs: {
    backgroundColor: '#F1F1F1',
    minHeight: '100vh',
    width: '100%'
  },
  container: {
    width: '100%',
    margin: '0',
    paddingBottom: '8rem',
    '@media (min-width:600px)': {
      paddingLeft: '2.5rem'
    }
  },
  heading: {
    fontSize: 45,
    lineHeight: '41px',
    letterSpacing: '-1px',
    '@media (min-width:600px)': {
      fontSize: 70,
      lineHeight: '68px',
      letterSpacing: '-2.5px',
    },
    '@media (min-width:900px)': {
      maxWidth: 700,
    },
    color: '#0B0B0B',
    paddingTop: 24,
    fontFamily: 'TargetBold'
  },
  heading2: {
    fontSize: 45,
    lineHeight: '41px',
    letterSpacing: '-1px',
    paddingTop: 0,
    '@media (min-width:600px)': {
      fontSize: 70,
      lineHeight: '68px',
      letterSpacing: '-2.5px',
    },
    '@media (min-width:900px)': {
      maxWidth: 1200,
    },
    color: '#0B0B0B',
    fontFamily: 'TargetBold'
  },
  divider: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.75rem'
  },
  stripe: {
    background: 'linear-gradient(90deg, rgba(234,67,243,1) 0%, rgba(237,108,77,1) 100%)',
    height: 16,
    width: '100%',
    marginTop: '.25rem'
  },
  questions: {
    maxWidth: '95%',
    '@media (min-width:600px)': {
      maxWidth: '70%',
    }
  },
  question: {
    textTransform: 'uppercase',
    fontFamily: 'TargetBold',
    fontSize: 22,
    lineHeight: '29.5px',
    marginTop: '3rem',
    marginBottom: '.25rem'
  },
  answer: {
    fontFamily: 'TargetLight',
    fontSize: 20,
    lineHeight: '24px'
  },
  item: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0
  },
  link: {
    textDecoration: 'none',
    color: '#FF10FF',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});
