import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
  Modal,
  Card,
  CardContent,
  IconButton,
  Typography,
  Backdrop,
  Fade,
  Grid,
  Box,
} from '@material-ui/core';
import InstaIcon from '../assets/images/instagram-icon.png';
import { eventText } from '../utils';
import modalImageHelper from '../utils/modalImageHelper';
import hostImageHelper from '../utils/hostImageHelper';

const OwnedEventModal = ({ open, onClose, type, ownedEvent }) => {
  const classes = modalStyles();

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      aria-labelledby='connect-task-modal-total'
      aria-describedby='connect-task-modal-description'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card className={classes.root}>
          <IconButton classes={{ root: classes.close }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <CardContent>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography classes={{ root: classes.title }}>{eventText[type].title}</Typography>
              </Grid>
              <Grid item xs={12} classes={{ root: classes.hostWrap }}>
                <Typography classes={{ root: classes.host }}>with {eventText[type].host}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} classes={{ root: classes.left }}>
                <Grid container spacing={0}>
                  <Grid item xs={12} classes={{ root: classes.imageContainer}}>
                    <img className={classes.image} src={modalImageHelper(type)} alt='event snapshot' />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={0} classes={{ root: classes.dates }}>
                      <div className={classes.dateBoxReserved}>
                        <Typography variant='body1' classes={{ root: classes.dateReserved }}>
                          {new Date(ownedEvent.event_start).toLocaleString().split(',')[0]}
                          {' '}
                          {new Date(ownedEvent.event_start).toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', timeZone: 'America/Chicago' })} CST
                        </Typography>
                        <Typography variant='body1' classes={{ root: classes.booked }}>BOOKED</Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} classes={{ root: classes.right }}>
                <Grid container spacing={0} classes={{ root: classes.rightWrap }}>
                  <Grid item xs={12}>
                    <Typography variant='body1' classes={{ root: classes.description }}>{eventText[type].description}</Typography>
                  </Grid>
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Typography variant='body1' classes={{ root: classes.meet }}>Meet your instructor:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='body1' classes={{ root: classes.about }}>{eventText[type].aboutHost}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box className={classes.aboutHost}>
                        <div className={classes.hostLeft}>
                          <a className={classes.link} href={eventText[type].hostInsta} rel='noreferrer' target='_blank'>
                            <div className={classes.iconContainer}>
                              <img src={InstaIcon} alt='instagram-icon' className={classes.icon} />
                            </div>
                            <div className={classes.hostText}>
                              <Typography variant='body1' classes={{ root: classes.name }}>{eventText[type].hostName}</Typography>
                            </div>
                          </a>
                        </div>
                        <div className={classes.hostRight}>
                          <img src={hostImageHelper(type)} alt='instructor profile pic' className={classes.hostPic} />
                        </div>
                      </Box>
                    </Grid>
                  </React.Fragment>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Fade>
    </Modal>
  );
};

export default OwnedEventModal;

const modalStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      position: 'absolute',
      width: '90%',
      maxWidth: 841,
      top: '50%',
      left: '50%',
      maxHeight: '80%',
      transform: 'translate(-50%, -50%)',
      overflow: 'scroll',
      borderRadius: 0,
      [theme.breakpoints.up('md')]: {
        width: '70%',
      },
    },
    close: {
      position: 'absolute',
      right: theme.spacing(0.25),
      top: theme.spacing(0.25),
      color: theme.palette.grey[600]
    },
    left: {
      marginTop: '1rem'
    },
    right: {
      marginTop: '1rem'
    },
    title: {
      fontFamily: 'TargetMedium',
      fontSize: 30,
      lineHeight: '30px',
      '@media (min-width:600px)': {
        lineHeight: '30px',
        fontSize: 39,
      },
      paddingTop: '1rem'
    },
    hostWrap: {
      paddingTop: 0
    },
    host: {
      fontFamily: 'TargetMedium',
      fontSize: 30,
      '@media (min-width:600px)': {
        fontSize: 39,  
      },
      lineHeight: '30px',
      marginBottom: 12,
      marginTop: '1rem'
    },
    imageContainer: {
      textAlign: 'center',
      maxHeight: 419,
      maxWidth: 396,
      margin: '0 auto',
      width: '100%',
      '@media (min-width:1200px)': {
        height: 419,
        width: 396
      }
    },
    image: {
      height: '100%',
      width: '100%'
    },
    description: {
      fontSize: 20,
      fontFamily: 'TargetMedium',
      lineHeight: '22px',
      width: '85%',
      marginTop: '.5rem'
    },
    info: {
      fontSize: 20,
      fontFamily: 'TargetMedium',
      lineHeight: '20px',
      marginTop: '2rem',
      width: '90%'
    },
    meet: {
      fontSize: 18,
      lineHeight: '21px',
      fontFamily: 'TargetMedium',
      marginTop: '2rem'
    },
    about: {
      fontSize: 18,
      lineHeight: '21px',
      fontFamily: 'TargetLight',
      width: '90%',
      whiteSpace: 'pre-line'
    },
    listHeader: {
      marginTop: '2rem',
      fontSize: 13,
      fontFamily: 'TargetMedium',
      lineHeight: '16px'
    },
    listItem: {
      fontSize: 13,
      fontFamily: 'TargetMedium',
      lineHeight: '13px',
      paddingTop: 4
    },
    rightWrap: {
      '@media (min-width:400px)': {
        marginLeft: '1rem'
      }
    },
    aboutHost: {
      display: 'flex',
      alignItems: 'flex-start',
      marginTop: '2rem',
      width: '90%',
      '@media (max-width:400px)': {
        position: 'relative',
      },
      '@media (max-width:800px)': {
        alignItems: 'center',
        flexDirection: 'column',
        // marginTop: '2rem'
      },
      '@media (min-width:800px)': {
        marginLeft: 0,
        // marginTop: '4rem'
      },
      '@media (min-width:1200px)': {
        width: '90%',
        // marginTop: '6rem'
      }
    },
    hostLeft: {
      display: 'flex'
    },
    hostRight: {
    },
    hostPic: {
      borderRadius: '50%',
      height: 107,
      width: 107,
      '@media (min-width:1200px)': {
        height: 214,
        width: 214,
      }
    },
    iconContainer: {
      height: '100%',
      width: 36,
      marginRight: '.5rem'
    },
    icon: {
      height: 36,
      width: 36
    },
    name: {
      fontSize: 12,
      fontFamily: 'TargetLight',
      lineHeight: '12px'
    },
    link: {
      textDecoration: 'none',
      color: 'black',
      display: 'flex'
    },
    dates: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginTop: '1rem',
      '@media (min-width:455px)': {
        justifyContent: 'flex-start'
      },
      '@media (min-width:600px)': {
        justifyContent: 'space-between',
      },
      '@media (min-width:876px)': {
        justifyContent: 'flex-start'
      }
    },
    dateBox: {
      border: '1px solid black',
      textAlign: 'center',
      width: 120,
      height: 42,
      margin: '.25rem 0 .25rem .25rem',
    },
    dateBoxAvailable: {
      border: '1px solid black',
      textAlign: 'center',
      width: 120,
      height: 42,
      margin: '.25rem 0 .25rem .25rem',
      '&:hover': {
        background: '#F1F1F1',
        color: 'white',
        cursor: 'pointer',
        border: '1px solid white'
      }
    },
    dateBoxBooked: {
      border: '1px solid lightgray',
      color: 'lightgray',
      textAlign: 'center',
      cursor: 'not-allowed',
      width: 120,
      height: 42,
      margin: '.25rem 0 .25rem .25rem',
    },
    date: {
      fontSize: 10,
      fontFamily: 'TargetMedium',
      lineHeight: '9.89px',
      letterSpacing: '-.4px',
      marginTop: '.5rem'
    },
    book: {
      fontSize: 16,
      fontFamily: 'TargetMedium',
      letterSpacing: '-.65px',
      lineHeight: '15.79px',
      marginTop: '.25rem',
      width: 88,
      marginRight: 'auto',
      marginLeft: 'auto'
    },
    dialogButtons: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '2rem 4rem',
      '@media (min-width:600px)': {
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '4rem 2rem 4rem',
        padding: '1rem'
      },
    },
    acceptButton: {
      backgroundColor: 'black',
      color: 'white',
      fontSize: 17,
      lineHeight: '22px',
      fontFamily: 'TargetBold',
      borderRadius: 50,
      height: 72,
      width: 160,
      textTransform: 'none',
      margin: '2rem auto',
      '@media (min-width:600px)': {
        marginLeft: '8rem'
      },
      '&:hover': {
        background: '#F1F1F1',
      }
    },
    cancelButton: {
      backgroundColor: 'white',
      color: 'black',
      fontSize: 11,
      lineHeight: '13px',
      fontFamily: 'TargetBold',
      borderRadius: 50,
      height: 72,
      width: 160,
      marginTop: '1rem',
      textTransform: 'none',
      border: '1px solid #525252',
      '@media (min-width:600px)': {
        marginTop: 0,
        marginRight: '8rem',
      },
      '&:hover': {
        border: '1px solid transparent'
      }
    },
    reserving: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    input: {
      backgroundColor: 'white',
      borderBottom: 'none'
    },
    inputLabel: {
      backgroundColor: 'transparent'
    },
    inputError: {
      borderColor: 'white !important',
      backgroundColor: 'white'
    },
    labelError: {
      color: 'red !important',
    },
    formWrap: {
      width: '95%',
      margin: '.5rem auto .5rem'
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '2rem',
      marginTop: '2rem'
    },
    button: {
      color: 'white',
      backgroundColor: 'black',
      fontFamily: 'TargetMedium',
      fontSize: 18,
      borderRadius: 40,
      textTransform: 'none',
      letterSpacing: '1.92px',
      width: 121,
      height: 51,
      boxShadow: '0px 3px 6px #00000029',
      '&:hover': {
        background: '#F1F1F1',
        color: 'white',
        cursor: 'pointer',
        border: 'none'
      }
    },
    dateReserved: {
      fontSize: 12,
      fontFamily: 'TargetMedium',
      lineHeight: '9.89px',
      letterSpacing: '-.4px',
      marginTop: '.5rem'
    },
    booked: {
      fontSize: 18,
      fontFamily: 'TargetMedium',
      letterSpacing: '-.65px',
      lineHeight: '15.79px',
      marginTop: '.5rem',
      width: 88,
      marginRight: 'auto',
      marginLeft: 'auto'
    },
    dateBoxReserved: {
      border: '1px solid black',
      textAlign: 'center',
      width: 148,
      height: 48,
      margin: '0 auto'
    }
  };
});
