import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import Events from '../pages/Events';
import Faqs from '../pages/Faqs';
// assets
import Logo from '../assets/images/Roundel-Logo.png';

const Home = () => {
  const classes = useStyles();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = props => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  };

  const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  return (
    <div className={classes.home}>
      <AppBar position='static'>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          classes={{ root: classes.tabs }}
          centered
          TabIndicatorProps={{
            style: {
              backgroundColor: 'transparent',
            }
          }}
        >
          <Tab label='Home' {...a11yProps(0)} />
          <Tab label='FAQs' {...a11yProps(1)}  />
          <div className={classes.logoContainer}>
            <img src={Logo} className={classes.logo} alt='Roundel Logo' />
          </div>
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Events />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Faqs />
      </TabPanel>
    </div>
  );
};

export default Home;

const useStyles = makeStyles({
  home: {
    backgroundColor: '#F1F1F1',
    minHeight: '100vh',
    width: '100%'
  },
  container: {
    width: '100%',
    margin: '0'
  },
  tabs: {
    background: '#000000',
    color: 'white',
    position: 'relative'
  },
  logoContainer: {
    position: 'absolute',
    top: 12,
    right: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 10,
    minWidth: 200,
  },
  logo: {
    height: 24,
    width: 'auto',
    display: 'none',
    '@media (min-width:450px)': {
      display: 'block'
    }
  }
});