import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import Login from '../pages/Login';
import Thanks from '../pages/Thanks';
import Home from './Home';

const App = props => {

  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const handleLogin = values => {
    dispatch({ type: 'AUTH_USER', values });
  };

  return (
    <div className='app'>
      <Router>
        <Switch>
          <Route exact path='/'>
            <Login updateAuth={values => handleLogin(values)} />
          </Route>
          <Route exact path='/thanks'>
            <Thanks />
          </Route>
          {auth.authenticated ? (
            <Route path='/home'>
              <Home />
            </Route>
          ) : <Redirect to='/' />}
        </Switch>
      </Router>
    </div>
  );
}

export default connect(s => s)(App);
