import Aperol from '../assets/images/Aperol_Card.png';

const cardImageHelper = type => {
  switch (type) {
    case 'aperolMarch':
      return Aperol;
    case 'aperolApril':
      return Aperol;
    case 'aperolMay':
      return Aperol;
    default:
      return;
  }
};

export default cardImageHelper;
