import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography } from '@material-ui/core';
import { eventText } from '../utils';
import cardImageHelper from '../utils/cardImageHelper';

const SoldOutEventCard = ({ data = [], type }) => {
  const classes = useStyles();

  return (
    <Paper classes={{ root: classes.soldOutEventCard }} square>
      <Grid container spacing={0} classes={{ root: classes.cardWrap }}>
        <Grid item xs={12}>
          <Typography variant='h6' classes={{ root: classes.dateHeading }}>March • April • May
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5' classes={{ root: classes.title }}>
            {eventText[type].title}
          </Typography>
        </Grid>
        <Grid item xs={12} classes={{ root: classes.imageContainer}}>
          <img className={classes.image} src={cardImageHelper(type)} alt='event snapshot' />
          <Typography variant='h3' classes={{ root: classes.overlay}}>SOLD OUT</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1' classes={{ root: classes.description }}>{eventText[type].frontDescription}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={0} classes={{ root: classes.dates }}>
            {data.map((date, i) => {
              const year = new Date(date.event_start).getFullYear().toString().substr(2, 2);
              const day = new Date(date.event_start).toLocaleString().split(',')[0]
              const time = new Date(date.event_start).toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', timeZone: 'America/Chicago' });
              return (
                <div key={`${date.id}_${i}`} className={classes.dateWrap}>
                  <div className={classes.dateBox}>
                    <Typography variant='body1' classes={{ root: classes.date }}>{`${day.slice(0, day.length - 4)}${year}`}{day}</Typography>
                    <Typography variant='body1' classes={{ root: classes.time }}>{time} CST</Typography>
                  </div>
                </div>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SoldOutEventCard;

const useStyles = makeStyles(theme => ({
  soldOutEventCard: {
    cursor: 'pointer',
    width: 320,
    maxWidth: 396,
    '@media (min-width:396px)': {
      width: 396,
      maxHeight: 730,
      height: 730,
    }
  },
  cardWrap: {
    padding: 15,
  },
  dateHeading: {
    borderBottom: '1px solid black',
    fontFamily: 'TargetMedium',
    fontSize: 16,
    marginTop: '1rem',
    opacity: '0.3'
  },
  title: {
    fontFamily: 'TargetBold',
    fontSize: 28,
    lineHeight: '27px',
    height: '3rem',
    marginTop: '.5rem',
    opacity: '0.3'
  },
  imageContainer: {
    textAlign: 'center',
    marginTop: '1.5rem',
    position: 'relative',
  },
  image: {
    width: '100%',
    height: 248,
    minWidth: '98%',
    opacity: '0.3'
  },
  overlay: {
    fontSize: 77,
    fontFamily: 'TargetBold',
    position: 'absolute',
    zIndex: 10,
    top: 30,
    opacity: '.6'
  },
  description: {
    fontFamily: 'TargetMedium',
    fontSize: 16,
    lineHeight: '16px',
    maxWidth: 333,
    marginTop: 10,
    marginBottom: 10,
    opacity: '0.3',
    height: 90
  },
  dates: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (min-width:396px)': {
      justifyContent: 'flex-start'
    },
    opacity: '0.3'
  },
  dateWrap: {
    width: 112,
    height: 38,
    margin: 5
  },
  dateBox: {
    border: '1px solid black',
    textAlign: 'center',
    width: 112,
    height: 38,
  },
  date: {
    fontSize: 14,
    fontFamily: 'TargetMedium',
    lineHeight: '9.89px',
    letterSpacing: '-.4px',
    marginTop: '.35rem'
  },
  time: {
    fontSize: 14,
    fontFamily: 'TargetMedium',
    letterSpacing: '-.65px',
    lineHeight: '15.79px',
    marginTop: '.25rem',
    width: 88,
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    border: '2px solid darkgray',
    boxShadow: '.5px',
    padding: '1rem',
  },
}));