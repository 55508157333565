import { combineReducers } from 'redux';

const initialUserState = {
  authenticated: false,
  roundelToken: null,
  splashToken: null,
  email: null
};

const initialEventState = {
  eventId: null
};

export const authReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case 'AUTH_USER':
      return {
        ...state,
        authenticated: true,
        email: action.values.email,
        roundelToken: action.values.token,
        splashToken: action.values.splashToken,
      };
    case 'UNAUTH_USER':
      return {
        ...state,
        authenticated: false,
        email: null,
        password: null,
        token: null
      };

    default:
      return state;
  }
};

export const eventReducer = (state = initialEventState, action) => {
  switch (action.type) {
    case 'SET_EVENT':
      return {
        ...state,
        event: action.values.event,
      };
    default:
      return state;
  }
};

export const rootReducer = combineReducers({
  auth: authReducer,
  event: eventReducer,
});
