import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import Logo from '../assets/images/Roundel-Logo.png';

const Thanks = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const logout = values => {
    dispatch({ type: 'UNAUTH_USER' });
  };

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.thanks}>
      <div className={classes.wrapper}>
        <div className={classes.logoContainer}>
          <img src={Logo} className={classes.logo} alt='Roundel Logo' />
        </div>
        <Container maxWidth='md' classes={{ root: classes.container }}>
          <div className={classes.top}>
            <Typography variant='h3' classes={{ root: classes.header}}>Thank you.</Typography>
            <Typography variant='h3' classes={{ root: classes.header}}>We're excited for your event!</Typography>
          </div>
        </Container>
      </div>
      <Container maxWidth='md' classes={{ root: classes.container }}>
        <Typography variant='h5' classes={{ root: classes.message1}}>If you have invited all your guests, they will receive invitations soon!</Typography>
        <Typography variant='h5' classes={{ root: classes.message2}}>If you need to return to manage your guest list, please do so in the near future to ensure all event kits will be received in time for your event.</Typography>
      </Container>
    </div>
  );
};

export default connect(s => s)(Thanks);

const useStyles = makeStyles({
  thanks: {
    backgroundColor: '#F1F1F1',
    minHeight: '100vh',
    width: '100%'
  },
  wrapper: {
    minHeight: '45vh',
    width: '100%',
    background: '#000000',
    paddingTop: '3rem'
  },
  logoContainer: {
    width: '100%',
    textAlign: 'right',
    marginBottom: 35,
    '@media (min-width:500px)': {
      textAlign: 'right',
      marginBottom: 50,
    }
  },
  logo: {
    height: '1.5rem',
    paddingRight: 10,
    '@media (min-width:500px)': {
      height: '2.5rem',
      marginRight: 53
    }
  },
  top: {
    marginTop: '12rem',
    paddingBottom: '2rem',
    '@media (min-width:800px)': {
      marginTop: '7rem',
    }
  },
  header: {
    fontFamily: 'TargetMedium',
    fontSize: 40,
    lineHeight: '40px',
    color: 'white',
    '@media (min-width:800px)': {
      fontSize: 60,
      lineHeight: '60px',
      maxWidth: 560,
    }
  },
  message1: {
    maxWidth: 600,
    marginTop: '3rem',
    fontSize: 20,
    lineHeight: '20px',
    fontFamily: 'TargetMedium',
    '@media (min-width:800px)': {
      fontSize: 26,
      lineHeight: '26px',
    }
  },
  message2: {
    maxWidth: 600,
    marginTop: '2rem',
    fontSize: 20,
    lineHeight: '20px',
    fontFamily: 'TargetMedium',
    '@media (min-width:800px)': {
      fontSize: 26,
      lineHeight: '26px',
    }
  }
});
