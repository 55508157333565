import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import BookedEventCard from './BookedEventCard';
import GuestEntry from './GuestEntry';

const OwnedEventList = ({ data, ownedEvents, handleAddEvent, handleLogout }) => {
  const classes = useStyles();

  const getOwnedEventType = type => {
    switch (type) {
      case 'Exhibits & Shows':
        return 'aperolMarch';
      case 'Seminars & Workshops':
        return 'aperolApril';
      case 'In-Store Engagment':
        return 'aperolMay';
      default:
        return 'aperolMarch';
    }
  };

  // detail view 
  const [selectedType, setSelectedType] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleViewEventDetail = (data, type) => {
    switch (type) {
      case 'aperolMarch':
        setSelectedType('aperolMarch');
        setSelectedEvent(data);
        break;
      case 'aperolApril':
        setSelectedType('aperolApril');
        setSelectedEvent(data);
        break;
      case 'aperolMay':
        setSelectedType('aperolMay');
        setSelectedEvent(data);
        break;
      default:
        setSelectedType('aperolApril');
        setSelectedEvent(data);
    }
  };

  const deselectEvent = () => {
    setSelectedType(null);
    setSelectedEvent(null);
  };

  return (
    <React.Fragment>
      <div className={classes.stripe} style={{ marginTop: '2rem' }} />
      <div className={classes.topRow}>
        <div className={classes.rowLeft}>
          <Typography variant='h6' classes={{ root: classes.welcomeText }}>Click on Event Below to Add or Edit Your Guest List</Typography>
        </div>
        <div className={classes.rowRight}>
          {!selectedEvent && (
            <React.Fragment>
              <Button
                variant='contained'
                classes={{ root: classes.addButton }}
                onClick={() => handleAddEvent()}
              >
                Add Additional Event
              </Button>
              <Button
                variant='contained'
                classes={{ root: classes.logoutButton }}
                onClick={() => handleLogout()}
              >
                Log Out
              </Button>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className={classes.eventList}>
        {!selectedEvent ? (
          <React.Fragment>
            {ownedEvents.map((o, i) => (
              <div className={classes.event} key={`${o.event_type.name}_${i}`}>
                <BookedEventCard
                  type={getOwnedEventType(o.event_type.name)}
                  ownedEvent={o}
                  handleClick={(data, type) => handleViewEventDetail(data, type)}
                />
              </div>
            ))}
          </React.Fragment>
        ) : (
          <GuestEntry
            ownedEvent={selectedEvent}
            selectedEvent={selectedEvent}
            selectedType={selectedType}
            deselectEvent={deselectEvent}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default OwnedEventList;

const useStyles = makeStyles({
  divider: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.5rem'
  },
  subHeading2: {
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    fontFamily: 'TargetMedium',
    '@media (min-width:600px)': {
      marginLeft: '1rem'
    }
  },
  stripe: {
    background: '#000000',
    height: 16,
    flex: '1 1',
    marginTop: '.25rem',
    marginLeft: '.25rem',
    '@media (min-width:600px)': {
      marginLeft: '1rem',
    }
  },
  topRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media (min-width:600px)': {
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  rowLeft: {

  },
  rowRight: {
    display: 'flex',
    width: '100%',
    marginTop: '1rem',
    '@media (min-width:600px)': {
      width: 350,
      marginTop: '1rem'
    },
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '1rem'
  },
  welcomeText: {
    marginTop: '1rem',
    paddingLeft: '1rem',
    fontSize: '2.25rem',
    lineHeight: '2.25rem',
    fontFamily: 'TargetMedium',
  },
  editText: {
    marginTop: '.5rem',
    paddingLeft: '1rem',
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    fontFamily: 'TargetMedium',
  },
  addButton: {
    color: 'white',
    backgroundColor: 'black',
    fontFamily: 'TargetMedium',
    fontSize: 18,
    lineHeight: '18px',
    borderRadius: 0,
    textTransform: 'none',
    letterSpacing: '1.92px',
    width: 151,
    height: 69,
    boxShadow: '0px 3px 6px #00000029',
    '&:hover': {
      backgroundColor: '#FF10FF',
      cursor: 'pointer',
      border: 'none'
    }
  },
  logoutButton: {
    color: 'white',
    backgroundColor: 'black',
    fontFamily: 'TargetMedium',
    fontSize: 18,
    lineHeight: '18px',
    borderRadius: 0,
    textTransform: 'none',
    letterSpacing: '1.92px',
    width: 151,
    height: 69,
    boxShadow: '0px 3px 6px #00000029',
    marginLeft: '1rem',
    '&:hover': {
      backgroundColor: '#FF10FF',
      cursor: 'pointer',
      border: 'none'
    }
  },
  eventList: {
    marginTop: '2rem',
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '.5rem',
    justifyContent: 'center',
    '@media (min-width:600px)': {
      justifyContent: 'flex-start'
    }
  },
  event: {
    margin: '1rem 0',
    width: 396,
    '@media (min-width:396px)': {
      height: 630,
    },
    '@media (min-width:600px)': {
      margin: '.25rem'
    }
  },
});
